import { requestToken } from '@/utils/request.js'
import service from '../utils/request'
import { getToken } from "../utils/auth";

// 查询商家信息 /merchant/queryMerchant
export const queryMerchant = (data) => {
    return requestToken('/merchant/queryMerchant', data)
}

// 编辑商家信息 /merchant/updateSingle
export const updateMerchant = (data) => {
    return requestToken('/merchant/updateSingle', data)
}


// 编辑商家logo /merchant/uploadFile
export const updateMerchantLogo = (data) => {
    const dealData = new FormData()
    dealData.append("token", getToken());
    for (let key in data) {
        dealData.append(key, data[key])
    }
    return service({
        data: dealData,
        method: 'post',
        url: '/merchant/uploadFile',
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}