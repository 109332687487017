<template>
    <div class="max-box">
        <!-- 头部搜索、操作 -->
        <div class="header-box">
            <img :src="currentData.logo" alt="" class="avatar"/>
            <span>{{ currentData.merchantName }}</span>
        </div>
        <!-- 内容区 -->
        <div class="content-box">
            <a-row :gutter="4">
                <a-col :span="12">
                    <!-- 注册信息 -->
                    <div class="col-style">
                        <div class="left-title">
                            <span>注册信息</span>
                            <span class="edit" @click="editOpen">编辑</span>
                        </div>
                        <div class="left-content">
                            <div v-for="item in registerMsg" class="left-one">
                                <span>{{ item.title }}</span>
                                <span>{{ item.value }} </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-style mt-16 minheight">
                        <div class="left-title"><span>各渠道消息发送统计</span></div>
                        <div>
                            <a-table
                                    :columns="columns"
                                    :data-source="tableData"
                                    :pagination="false"
                            ></a-table>
                        </div>
                    </div>
                </a-col>
                <a-col :span="12">
                    <div class="col-style">
                        <div class="left-title">
                            <span>联系人信息</span>
                        </div>
                        <div class="left-content">
                            <div class="information-box">
                                <div class="information-one">
                                    <div class="icon-title">
                                        <a-icon type="user" class="icon"/>
                                        <span>联系人姓名</span>
                                    </div>
                                    <div>{{ contact }}</div>
                                </div>
                                <div class="information-one">
                                    <div class="icon-title">
                                        <a-icon type="phone" class="icon"/>
                                        <span>手机号</span>
                                    </div>
                                    <div>{{ tel }}</div>
                                </div>
                                <div class="information-one">
                                    <div class="icon-title">
                                        <a-icon type="mail" class="icon"/>
                                        <span>邮箱</span>
                                    </div>
                                    <div>{{ email }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-style mt-16 minheight">
                        <div class="left-title">
                            <span>商家logo</span>
                            <span class="edit" @click="openLogo">编辑</span>
                        </div>

                        <div class="img-box">
                            <img :src="currentData.logo" alt=""/>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <!-- 编辑商家信息-->
        <a-drawer
                placement="top"
                :closable="false"
                :visible="addVisible"
                height="100%"
                wrapClassName="add-power"
                @close="closeTopDrawer"
                :destroyOnClose="true"
                :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
        flexGrow: 0,
      }"
                :bodyStyle="{
        padding: '0.3rem 0 0 0',
        flexGrow: 1,
      }"
                :drawerStyle="{
        display: 'flex',
        flexDirection: 'column',
      }"
        >
            <template #title>
                <div class="head-box">
                    <span>编辑商家</span>
                    <div class="head-btn">
                        <a-button @click="closeTopDrawer">取消</a-button>
                        <a-button type="primary" class="save-btn" @click="savePlan"
                        >保存
                        </a-button
                        >
                    </div>
                </div>
            </template>
            <div class="content-box">
                <div class="center-box">
                    <a-form-model
                            ref="ruleForm"
                            :model="form"
                            :rules="rules"
                            class="forms"
                    >
                        <a-row>
                            <a-col :span="12">
                                <a-form-model-item label="商户名称" prop="merchantName">
                                    <a-input
                                            v-model="form.merchantName"
                                            style="width: 80%"
                                    ></a-input>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="联系电话" prop="telPhone">
                                    <a-input v-model="form.telPhone" style="width: 80%"></a-input>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12">
                                <a-form-model-item label="联系人" prop="contact">
                                    <a-input v-model="form.contact" style="width: 80%"></a-input>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-model-item label="详细地址" prop="address">
                                    <a-input v-model="form.address" style="width: 80%"></a-input>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-form-model-item label="商户描述">
                            <a-input
                                    v-model="form.merchantDesc"
                                    style="width: 100%"
                            ></a-input>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
        </a-drawer>

        <a-modal
                title="编辑商家logo"
                :visible="logoModal"
                @ok="logoOk"
                @cancel="logoCancel"
        >
            <div class="upload-box">
                <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        :before-upload="beforeUpload"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
                    <div v-else>
                        <a-icon type="plus"/>
                    </div>
                </a-upload>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import moment from "moment";
    // import echartConfig from "./config/alarmEchart";
    import {
        queryMerchant,
        updateMerchant,
        updateMerchantLogo,
    } from "@/api/merchant.js";

    export default {
        data() {
            return {
                registerMsg: [
                    {
                        value: "--",
                        title: "商家名称(简体中文)",
                        prop: "merchantName",
                    },
                    {
                        value: "--",
                        title: "商家地区",
                        prop: "address",
                    },
                    {
                        value: "--",
                        title: "商户描述",
                        prop: "merchantDesc",
                    },
                ],
                columns: [
                    {
                        title: "渠道",
                        dataIndex: "method",
                    },
                    {
                        title: "剩余",
                        dataIndex: "remaining",
                    },
                ],
                tableData: [],
                form: {
                    merchantName: "",
                    telPhone: "",
                    contact: "",
                    address: "",
                    merchantDesc: "",
                },
                rules: {
                    merchantName: [
                        {
                            required: true,
                            message: "请填写商户名称",
                            trigger: "blur",
                        },
                    ],
                    telPhone: [
                        {
                            required: true,
                            message: "请填写联系电话",
                            trigger: "blur",
                        },
                    ],
                    contact: [
                        {
                            required: true,
                            message: "请填写联系人",
                            trigger: "blur",
                        },
                    ],
                    address: [
                        {
                            required: true,
                            message: "请填写详细地址",
                            trigger: "blur",
                        },
                    ],
                },
                addVisible: false,
                contact: "--",
                tel: "--",
                email: "--",
                logoModal: false,
                imageUrl: "",
                currentData: {},
                fileData: null,
            };
        },
        methods: {
            editOpen() {
                this.form.merchantName = this.currentData.merchantName;
                this.form.telPhone = this.currentData.telPhone;
                this.form.contact = this.currentData.contact;
                this.form.address = this.currentData.address;
                this.form.merchantDesc = this.currentData.merchantDesc;
                this.addVisible = true;
            },
            closeTopDrawer() {
                this.addVisible = false;
            },
            savePlan() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        updateMerchant({...this.form, id: this.currentData.id}).then(
                            (res) => {
                                console.log(res);
                                this.getMerchantMsg();
                                this.addVisible = false;
                            }
                        );
                    }
                });
            },
            getMerchantMsg() {
                queryMerchant({}).then((res) => {
                    this.currentData = res.data;
                    console.log(this.currentData);
                    this.registerMsg.forEach((item) => {
                        for (let k in res.data) {
                            if (k == item.prop) {
                                item.value = res.data[k];
                            }
                        }
                    });
                    this.contact = res.data.contact;
                    this.tel = res.data.telPhone;
                    this.tableData = [
                        {method: "邮件", remaining: res.data.emailNumber},
                        {method: "短信", remaining: res.data.messageNumber},
                    ];
                });
            },
            openLogo() {
                this.logoModal = true;
            },
            logoOk() {
                this.ownUpload();
                this.logoModal = false;
            },
            logoCancel() {
                this.logoModal = false;
                this.imageUrl = "";
                this.fileData = null;
            },
            beforeUpload(file) {
                this.fileData = file;
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imageUrl = event.target.result; // 将本地图片的 URL 存储到 imageUrl 中
                };
                reader.readAsDataURL(file);
                return false;
            },
            ownUpload() {
                const formdata = {
                    multipartFile: this.fileData,
                    id: this.currentData.id,
                };
                updateMerchantLogo(formdata).then((res) => {
                    this.getMerchantMsg();
                    this.fileData = null;
                    this.imageUrl = "";
                });
            },
            handleChange() {
            },
        },
        mounted() {
            this.getMerchantMsg();
        },
    };
</script>
<style lang="less" scoped>
    .max-box {
        width: 100%;
        padding-top: 16px;
        padding-right: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;

        .header-box {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            span {
                color: #252b3a;
                font-size: 18px;
                margin-left: 8px;
            }
        }

        .content-box {
            .col-style {
                width: 100%;
                padding: 16px 0;
                // height: 300px;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                border: 1px solid rgba(37, 43, 58, 0.1);
                border-radius: 4px;
                background: #fff;

                .left-title {
                    font-size: 14px;
                    color: #515561;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 16px;
                    margin-bottom: 16px;

                    .edit {
                        color: #048fff;
                        cursor: pointer;
                    }
                }

                .img-box {
                    margin-top: 16px;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 104px;
                        height: 104px;
                        border-radius: 50%;
                        //   margin: 0 auto;
                    }
                }

                .left-content {
                    padding: 0 16px;

                    .information-box {
                        width: 80%;
                        margin: 0 auto;
                        padding: 0 20px;

                        .information-one {
                            display: flex;
                            margin-bottom: 24px;

                            .icon-title {
                                width: 40%;

                                .icon {
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                    .left-one {
                        font-size: 12px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 24px;
                    }
                }
            }

            .minheight {
                min-height: 284px;
            }
        }
    }

    .add-power {
        .head-box {
            display: flex;
            height: 64px;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 20px;
                color: #252b3a;
            }

            .head-btn {
                .ant-btn {
                    height: 32px;
                    font-size: 12px;
                    margin-left: 10px;
                }
            }
        }

        .content-box {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            .center-box {
                width: 80%;
                height: 100%;

                .forms {
                    width: 100%;

                    .ant-form-item {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    .upload-box {
        width: 100%;
        display: flex;
        justify-content: center;

        /deep/ .avatar-uploader {
            width: 128px;
            height: 128px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
